<template>
  <div class="feature">
    <div class="title">
      As Featured In
    </div>
    <div class="content">
      <div class="icons-section">
        <img class="icons" src="./../../assets/imgs/pc/featuredIn/smartCitiesTech.png" alt="">
        <img class="icons" src="./../../assets/imgs/pc/featuredIn/startupDaily.png" alt="">
        <img class="icons" src="./../../assets/imgs/pc/featuredIn/marketingInAsiaStartup.png" alt="">
      </div>
    </div>
  </div>
</template>


<script>
export default {
  
}
</script>


<style lang="scss" scoped>

.feature{
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title{
  // 精确调整trusted by的标题位置
    // position: relative;
    // left: 10px;
    margin:0 0 20px 0;
    width: 84px;
    height: 18px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 12px;
    font-stretch: normal;
    line-height: 18px;
    letter-spacing: normal;
    // text-align: left;
    color: #A9AEB8;
    white-space: nowrap;
}

.icons{
  width: 96px;
  height: 36px;
  margin: 0 14px;
}
</style>
